<template>
  <div class="elderlyCareAppointment">
    <div class="section-box">
      <div class="section-new">
        <div class="content">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="content-item"
              v-for="(item, index) in listData"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="item-wrapper">
                <div class="item-left">
                  <img
                    :src="item.roomPic ? item.roomPic.split(',')[0] : ''"
                    alt=""
                  />
                </div>
                <div class="item-right">
                  <div class="content-item--text">
                    {{ `${item.roomName}-${item.bedName}` }}
                  </div>
                  <div class="content-item--info">
                    {{ item.roomArea }}m²
                    <div class="line"></div>
                    {{ item.roomOrientation }}
                  </div>
                  <div class="content-item--operate">
                    <div class="operate-status">
                      {{ orderStatusMap[item.orderStatus] }}
                    </div>
                    <div
                      class="operate-button"
                      v-if="item.orderStatus == 0 || item.orderStatus == 1"
                    >
                      <div
                        class="operate-button--status"
                        :style="item.orderStatus | btnCss"
                      >
                        {{ item.orderStatus | btnTxt }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item-date">
                <span>下单时间：</span>
                <span>{{ item.createTime }}</span>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { toRegister } from "@/utils/common";
import { orderListPagingURL } from "./api";
import { orderStatusMap } from "./map";

export default {
  name: "elderlyCareAppointmentAudioOrder",
  data() {
    return {
      orderStatusMap,
      /* v-list 通用 */
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      /** end */
    };
  },
  filters: {
    btnTxt(status) {
      const btnStatusMap = {
        0: "待审核",
        1: "编辑",
        2: "编辑",
        3: "",
      };
      return btnStatusMap[status];
    },
    btnCss(status) {
      let btnStatusCSS = {
        0: {
          color: "#FFFFFF",
          background: "#138347",
        },
        1: {
          color: "#FFFFFF",
          background: "#138347",
        },
        2: {
          color: "#FFFFFF",
          background: "#138347",
        },
        3: {
          color: "#FFFFFF",
          background: "#7CC0A3",
        },
        // 2: {
        //   color: "#FFFFFF",
        //   background: "#7CC0A3",
        // },
      };
      return btnStatusCSS[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  methods: {
    async clickCall() {
      if (await toRegister(this.$route.path, this.$route.query, "养老预约")) {
        window.location.href = `tel:${this.phone}`;
      }
    },
    // 点击标题跳转议题详情页
    async toDetail(item) {
      if (await toRegister(this.$route.path, this.$route.query, "养老预约")) {
        this.$router.push({
          name: "elderlyCareAppointmentOrderDetail",
          query: {
            id: item.id,
            isAudio: true,
          },
        });
      }
    },

    /* 接口 */
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(orderListPagingURL, {
          params: {
            // houseId: this.houseId || this.communityId || undefined,
            tenantId: this.tenantId,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* 辅助方法 */
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elderlyCareAppointment {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    // padding: 36px 30px;
    .section-new {
      .content {
        // padding: 34px 24px 0;
        border-radius: 0 0 16px 16px;
        .content-item {
          box-sizing: border-box;
          width: 100%;
          border-radius: 16px;
          padding: 48px 24px 20px;
          background: #ffffff;
          &:not(:first-child) {
            margin-top: 30px;
          }
          .item-wrapper {
            display: flex;
            .item-left {
              width: 250px;
              min-width: 250px;
              display: flex;
              align-items: center;
              margin-right: 26px;
              border-radius: 8px;
              overflow: hidden;
              img {
                width: 100%;
                height: 250px;
                vertical-align: middle;
                object-fit: cover;
              }
            }
            .item-right {
              width: calc(100% - 276px);
              .content-item--text {
                font-weight: bold;
                line-height: 76px;
                font-size: 52px;
                color: rgba(0, 0, 0, 0.85);
                &:extend(.dhsl);
              }
              .content-item--info {
                width: 100%;
                font-size: 48px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 68px;
                &:extend(.dhsl-1);
                > .line {
                  box-sizing: border-box;
                  display: inline-block;
                  vertical-align: middle;
                  height: 68px;
                  // height: 100%;
                  padding: 12px 40px 16px 24px;
                  overflow: hidden;
                  &::before {
                    content: "";
                    display: block;
                    width: 6px;
                    min-width: 6px;
                    height: 40px;
                    background: rgba(0, 0, 0, 0.85);
                    border-radius: 2px;
                  }
                }
              }
              .content-item--operate {
                margin: 28px 0 0px;
                display: flex;
                align-items: flex-end;
                .operate-status {
                  flex: 1;
                  color: rgba(0, 0, 0, 0.5);
                  font-size: 44px;
                }
                .operate-button {
                  display: flex;
                  justify-content: flex-end;
                  .operate-button--status {
                    border-radius: 10px;
                    white-space: nowrap;
                    font-size: 44px;
                    line-height: 60px;
                    padding: 12px 36px;
                  }
                }
              }
            }
          }
          .item-date {
            font-size: 36px;
            padding: 18px 10px 0;
            margin: 30px 0px 0;
            color: rgba(0, 0, 0, 0.5);
            line-height: 68px;
            border-top: 2px solid #f0f0f0;
          }
        }
      }
    }
  }
}
</style>
